
/** Дата в формате backend'a */
export function dateBackendFormat(date: string) {
  const parts = date.split('.')
  if (parts.length !== 3) {
    return date
  }

  const year = parts[2]
  const month = parts[1]
  const day = parts[0]
  return `${year}-${month}-${day}`
}