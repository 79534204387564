import { useToken } from '@/shared'
import { useWebSocket } from '@vueuse/core'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { Mortgage } from '@/entities/mortgage'
import { Akbars, Chatmessage, ReportsAsync } from 'simpleloan_api'
import { useAuthModel } from '@/features/auth'

export type MortgageSocketCreate = {
  _type: 'mortgage_create'
  _data: {
    app: Mortgage
  }
}

export type MortgageSocketUpdate = {
  _type: 'mortgage_update'
  _data: {
    app: Mortgage
  }
}

export type MortgageSocketBankStatus = {
  _type: 'mortgage_bank_status'
  _data: {
    app: Mortgage
  }
}

export type WebsocketAsyncReport = {
  _data: ReportsAsync
  _type: 'async_report'
}

export type WebSocketAkbarsEvents =
  | 'akbars_app'
  | 'akbars_scans'
  | 'akbars_processing'
  | 'akbars_loaner_approving'
  | 'akbars_printforms'
  | 'akbars_pledge_approving'
  | 'akbars_issuing'
  | 'akbars_end'
  | 'akbars_cancel'

export type WebSocketAkbars = {
  _data: Akbars
  _type: WebSocketAkbarsEvents
}

export type WebSocketChatmessageCreate = {
  _type: 'chatmessage_create'
  _data: Chatmessage
}

export type WebSocketChatmessageDelete = {
  _type: 'chatmessage_delete'
  _data: Chatmessage
}

export type WebSocketChatmessageRead = {
  _type: 'chatmessage_read'
  _data: Chatmessage
}

export type WebsocketData =
  | MortgageSocketCreate
  | MortgageSocketUpdate
  | MortgageSocketBankStatus
  | WebsocketAsyncReport
  | WebSocketAkbars
  | WebSocketChatmessageCreate
  | WebSocketChatmessageDelete
  | WebSocketChatmessageRead

export const useWebsocketStore = defineStore('websocket', () => {
  const authModel = useAuthModel()
  const { isAuthorized } = storeToRefs(authModel)
  const domain = process.env.NODE_ENV === 'development' ? 'slb.medv.ru' : 'portal.simpleloan.ru'
  const wssUrl = ref(`wss://${domain}/api/v1/ws/?token=${useToken()}`)

  const { status, data, send, open, close } = useWebSocket(wssUrl, {
    immediate: true,
    autoReconnect: {
      delay: 5000,
      onFailed: () => alert('Failed to connect WebSocket')
    },
    onDisconnected: () => console.log('onDisconnected'),
    onError: () => console.log('websocket error'),
    onConnected: () => console.log('websocket connect')
  })

  const message = computed<WebsocketData>(() => JSON.parse(data.value))

  watch(message, () => console.log('websocket message', message.value))

  watch(
    isAuthorized,
    isAuthorized => {
      if (isAuthorized) {
        changeWssUrl()
        open()
      } else {
        close()
      }
    },
    { immediate: true }
  )

  function changeWssUrl() {
    wssUrl.value = `wss://${domain}/api/v1/ws/?token=${useToken()}`
  }

  return { status, message, send, open, close }
})
