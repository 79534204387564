import { useAuthModel } from '@/features/auth'
import { storeToRefs } from 'pinia'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

async function pageGuard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const authModel = useAuthModel()

  const { checkLogin } = authModel
  const { isAuthorized } = storeToRefs(authModel)

  if (isAuthorized.value) {
    return from
  }

  if (!isAuthorized.value) {
    const res = await checkLogin()

    if (res?._code === 0) {
      return from
    }
  }

  return true
}

export const routesNames = {
  login: 'AuthLogin'
}

export const routes: RouteRecordRaw = {
  path: '/auth',
  redirect() {
    return { path: routesNames.login }
  },
  children: [
    {
      path: 'login',
      name: routesNames.login,
      beforeEnter: [pageGuard],
      component: () => import('@/pages/AuthLoginPage')
    }
  ]
}
