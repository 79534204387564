import { DictList } from '@/shared'
import { useToast } from 'primevue/usetoast'
import { Ref, ComputedRef, computed, toRefs } from 'vue'
import { Customer, CustomerFields } from '@/entities/customer'
import { Form, Nullable } from '@/shared'
import { useMortgageModel } from '../mortgage-model'
import { MortgageScan } from '../../types'

/**
 ** Фабричная функция, которая создает модель для работы с удалением кастомера
 */
const useMortgageCustomerDeleteModel = (
  customerId: Ref<string | null>,
  customer: ComputedRef<Customer | null>,
  customerType: ComputedRef<'main' | 'customer'>,
  form: Form<Partial<Nullable<CustomerFields>>>,
  doctypes: Ref<DictList | null>,
  scans: Ref<MortgageScan[] | null>,
  getCustomerFio: () => string
) => {
  const { add: addToast } = useToast()
  const mortgageModel = useMortgageModel()
  const { mortgage, deleteCustomerPending: pending } = toRefs(mortgageModel)

  const disabled = computed(() => {
    if (!mortgage.value || !customer.value || pending.value) {
      return true
    }

    return false
  })

  const disabledReason = computed(() => {
    if (!mortgage.value) {
      return 'Заявка еще не создана'
    }

    if (!customer.value) {
      return 'Созаёмщик еще не создан'
    }

    if (pending.value) {
      return 'Происходит удаление созаёмщика'
    }

    return null
  })

  async function deleteCustomer() {
    if (customer.value && customerType.value === 'customer') {
      const res = await mortgageModel.deleteCustomer(customer.value.id)
      if (res?._code === 0) {
        form.reset()
        scans.value = null
        doctypes.value = null
        customerId.value = null
        // addToast({ severity: 'success', summary: `Созаёмщик удален`, detail: getCustomerFio() })
      } else {
        addToast({
          severity: 'error',
          summary: `Удаление созаёмщика`,
          detail: `${getCustomerFio()}. ${res?._message || ''}`.trim()
        })
      }
      return res
    }
  }

  return { deleteCustomer, pending, disabled, disabledReason }
}

export default useMortgageCustomerDeleteModel
