import { User } from "@/shared"

export function userHavePermission(user: User, permission: string) {
  return !!user.raw_permissions?.find(perm => perm === permission)
}

export function userIsAdmin(user: User) {
  return userHavePermission(user, 'mortgage_get_all')
}

export function userIsModerator(user: User) {
  return userHavePermission(user, 'mortgage_get_sp')
}

export function userIsModeratorKboAll(user: User) {
  return userHavePermission(user, 'mortgage_get_bc')
}
