<template>
  <InputMask
    v-model="localModel"
    v-bind="props"
    unmask
    mask="9999 999999"
    :disabled="disabled"
    :invalid="invalid"
    :readonly="readonly"
    :autoClear="false"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import { defineModel, ref } from 'vue'
import InputMask from 'primevue/inputmask'
import { watchIgnorable } from '@vueuse/core'

const props = defineProps<{
  disabled?: boolean
  invalid?: boolean
  readonly?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const serial = defineModel<string | null | undefined>('serial', { required: true })
const number = defineModel<string | null | undefined>('number', { required: true })

const localModel = ref<string | null>(`${serial.value || ''}${number.value || ''}`.trim())

const { ignoreUpdates: ignoreUpdatesLocalModel } = watchIgnorable(localModel, localModel => {
  ignoreUpdatesSerial(() => {
    const newSerial = localModel?.substring(0, 4) || null
    if (newSerial !== serial.value) {
      serial.value = localModel?.substring(0, 4) || null
    }
  })

  ignoreUpdatesNumber(() => {
    const newNumber = localModel?.substring(4) || null
    if (newNumber !== number.value) {
      number.value = newNumber
    }
  })
})

const { ignoreUpdates: ignoreUpdatesSerial } = watchIgnorable(serial, () => {
  const newValue = `${serial.value || ''}${number.value || ''}`.trim()
  if (localModel.value !== newValue) {
    ignoreUpdatesLocalModel(() => {
      localModel.value = newValue
    })
  }
})

const { ignoreUpdates: ignoreUpdatesNumber } = watchIgnorable(number, () => {
  const newValue = `${serial.value || ''}${number.value || ''}`.trim()
  if (localModel.value !== newValue) {
    ignoreUpdatesLocalModel(() => {
      localModel.value = newValue
    })
  }
})
</script>
