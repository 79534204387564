<template>
  <InputMask
    v-model="model"
    v-bind="props"
    unmask
    mask="+7 (999) 999 99 99"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import InputMask from 'primevue/inputmask'
import { defineModel } from 'vue'

const props = defineProps<{
  id?: string,
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  readonly?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const model = defineModel<string | null>()
</script>
