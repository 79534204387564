import { RouteRecordRaw } from 'vue-router'

export const routesNames = {
  threads: 'Chatmessage Threads',
  globalMessage: 'Chatmessage Global Message',
}

export const routes: RouteRecordRaw = {
  path: '/chatmessage',
  meta: { header: true },
  children: [
    {
      path: '',
      name: routesNames.threads,
      component: () => import('@/pages/ChatMessagePage')
    },
    {
      path: 'global-message',
      name:  routesNames.globalMessage,
      component: () => import('@/pages/ChatmessageGlobalMessagePage')
    }
  ]
}
