export function getBaseUrl() {
  switch (process.env.NODE_ENV) {
    case 'development':
      return 'https://slb.medv.ru/' // URL среды разработки
    case 'production':
      return 'https://portal.simpleloan.ru/' // URL производственной среды
  }
}

export async function downloadFile(url: string, filename: string) {
  const response = await fetch(getBaseUrl() + url)
  const blob = await response.blob()
  const file = new File([blob], filename, { type: blob.type })
  return file
}
