import { fileTob64, useUtilsPdfPreview } from '@/shared'
import { useToast } from 'primevue/usetoast'
import { computed, ComputedRef, ref } from 'vue'

export const useUtilsPdfDivideModel = () => {
  const { post, pending, error, abort } = useUtilsPdfPreview()
  const { add: addToast } = useToast()

  const scans = ref<string[] | null>(null)
  const isOpen = ref(true)

  const divide = async (rawFile: File): Promise<void> => {
    const file = await fileTob64(rawFile)
    const res = await post({ _data: { file } })

    if (!isOpen.value) {
      return
    }

    if (res?._code === 0 && res?._data) {
      scans.value = res?._data.map(scan => `data:image/jpeg;base64, ${scan}`)
    } else {
      addToast({ severity: 'error', summary: 'Разделение pdf файлов', detail: error })
    }
  }

  const closeModal = (): void => {
    isOpen.value = false
    abort()
  }

  const disabledReason: ComputedRef<string | null> = computed(() => {
    if (pending.value) {
      return 'Идет разделение файлов...'
    }

    return null
  })

  return { divide, error, scans, closeModal, disabledReason }
}
