<template>
  <AutoComplete
    v-model="localModel"
    :disabled="disabled"
    :invalid="invalid"
    :placeholder="placeholder"
    :suggestions="suggestions"
    input-class="w-full"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
    @item-select="onItemSelect"
  />
</template>

<script setup lang="ts">
import { useCommondictsFms } from '@/shared';
import { watchIgnorable } from '@vueuse/core'
import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete'
import { defineModel, nextTick, ref } from 'vue'

defineProps<{
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  readonly?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const { post } = useCommondictsFms()
const divisionCode = defineModel<string | null>('divisionCode')
const issuer = defineModel<string | null>('issuer')

const localModel = ref<string>(`${divisionCode.value || ''} ${issuer.value || ''}`.trim())
const suggestions = ref<string[]>([])
const searchCode = ref<string | null>(null)

const { ignoreUpdates: ignoreUpdatesLocalModel } = watchIgnorable(localModel, () => {
  updateFields()
})

// Отдельно потому что в месте не работает
const { ignoreUpdates: ignoreUpdatesDivisionCode } = watchIgnorable(divisionCode, () => {
  ignoreUpdatesLocalModel(() => {
    localModel.value = `${divisionCode.value || ''} ${issuer.value || ''}`.trim()
  })
})
const { ignoreUpdates: ignoreUpdatesIssuer } = watchIgnorable(issuer, () => {
  ignoreUpdatesLocalModel(() => {
    localModel.value = `${divisionCode.value || ''} ${issuer.value || ''}`.trim()
  })
})

async function search(event: AutoCompleteCompleteEvent) {
  const code = event.query.replace(/\D/g, '').slice(0, 6)
  if (code.length === 6) {
    const codeArray = code?.split('')
    codeArray?.splice(3, 0, '-')
    codeArray?.join('')
    suggestions.value = await getFms(codeArray?.join(''))
  } else {
    searchCode.value = null
    suggestions.value = []
  }
}

async function getFms(code: string) {
  const res = await post({ _data: { code } })
  if (res?._code === 0 && res._data) {
    searchCode.value = code
    return [...res._data.EXTERNAL, ...res._data.INTERNAL]
  }

  searchCode.value = null
  return []
}

function onItemSelect(event: AutoCompleteItemSelectEvent) {
  nextTick(() => {
    const value = event.value as string
    const newStr = `${divisionCode.value} ${value}`
    localModel.value = newStr
  })
}

function updateFields() {
  const parts = localModel.value.split(' ')
  let _divisionCode = parts[0]
  if (_divisionCode.length === 6 && _divisionCode.indexOf('-') === -1) {
    _divisionCode =
      _divisionCode[0] +
      _divisionCode[1] +
      _divisionCode[2] +
      '-' +
      _divisionCode[3] +
      _divisionCode[4] +
      _divisionCode[5]
  }
  const _issuer = parts.slice(1).join(' ')

  ignoreUpdatesDivisionCode(() => {
    divisionCode.value = searchCode.value || _divisionCode
  })
  ignoreUpdatesIssuer(() => {
    issuer.value = _issuer
  })
}
</script>
