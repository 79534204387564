<template>
  <div class="grid grid-cols-12 gap-x-4">
    <template
      v-for="i in numberOfChild"
      :key="i"
    >
      <BaseInputForm
        :label="`Дата рождения ребенка ${i}`"
        :error="getErrorChildBirthDates(i - 1)"
        class="col-span-6"
      >
        <InputDate
          v-model="localChildBirthDates[i - 1]"
          :invalid="Boolean(getErrorChildBirthDates(i - 1))"
        ></InputDate>
      </BaseInputForm>

      <!-- v-if="bankName === 'alfabankbalance'" -->
      <BaseInputForm
        v-if="citizenshipVisible"
        :label="`Гражданство ребенка ${i}`"
        :error="getErrorChildCitizenship(i - 1)"
        class="col-span-6"
      >
        <Dropdown
          v-model="localChildCitizenship[i - 1]"
          filter
          show-clear
          option-label="title"
          option-value="code"
          :invalid="Boolean(getErrorChildCitizenship(i - 1))"
          :options="citizenshipOptions"
        />
      </BaseInputForm>
    </template>
  </div>
</template>

<script setup lang="ts">
import { defineModel, onBeforeMount, reactive, ref, watch } from 'vue'
import { BankName, DictList, TObject } from '@/shared'
import { BaseInputForm, InputDate, requiredValidator, useGeneralGetDict } from '@/shared'
import Dropdown from 'primevue/dropdown'

const props = defineProps<{
  numberOfChild: number
  bankName: BankName
  citizenshipVisible?: boolean
}>()

/**
 * * На беке значения хранятся в виде массива ["2001-02-02", "2002-03-03"]
 * * Но отправлять должны строку в виде "2001-02-02, 2002-03-03"
 */

/** Даты рождения детей */
const childBirthDates = defineModel<string[] | string | null>('birthDates')
/** Страна рождения детей */
const childCitizenship = defineModel<string[] | string | null>('citizenship')

/** Локальное значение даты рождения детей */
const localChildBirthDates = reactive<TObject<string, string | null>>({})
/** Локальное значение страны рождения детей */
const localChildCitizenship = reactive<TObject<string, string | null>>({})
/** Справочник по гражданствам */
const citizenshipOptions = ref<DictList>()

// Если значение - это массив, то необходимо заполнить локальные данные
if (Array.isArray(childBirthDates.value)) {
  childBirthDates.value.forEach((date, i) => {
    localChildBirthDates[i] = date
  })
}

// Если значение - это массив, то необходимо заполнить локальные данные
if (Array.isArray(childCitizenship.value)) {
  childCitizenship.value.forEach((citizenship, i) => {
    localChildCitizenship[i] = citizenship
  })
}

onBeforeMount(() => {
  useGeneralGetDict('Country').then(dict => {
    if (dict) {
      citizenshipOptions.value = dict
    }
  })
})

watch(
  () => props.numberOfChild,
  numberOfChild => {
    if (numberOfChild) {
      // Если число детей изменилось (уменьшилось), то нужно убрать лишние поля
      Object.keys(localChildBirthDates).forEach(key => {
        if (Number(key) + 1 > numberOfChild) {
          delete localChildBirthDates[key]
        }
      })

      // Если число детей изменилось (уменьшилось), то нужно убрать лишние поля
      Object.keys(localChildCitizenship).forEach(key => {
        if (Number(key) + 1 > numberOfChild) {
          delete localChildCitizenship[key]
        }
      })
    }
  }
)

watch(localChildBirthDates, localChildBirthDates => {
  childBirthDates.value = Object.values(localChildBirthDates).join(',')
})

watch(localChildCitizenship, localChildCitizenship => {
  childCitizenship.value = Object.values(localChildCitizenship).join(',')
})

function getErrorChildCitizenship(i: number) {
  const result = requiredValidator(localChildCitizenship[i])
  if (result === true) {
    return null
  }

  return result
}

function getErrorChildBirthDates(i: number) {
  const result = requiredValidator(localChildBirthDates[i])
  if (result === true) {
    return null
  }

  return result
}
</script>
