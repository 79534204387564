<template>
  <form @submit.prevent="onSubmit">
    <div class="flex flex-col">
      <ControlForm
        class="col-span-full"
        label="Email"
        :control="email"
      >
        <template #field="{ invalid }">
          <InputText
            id="email"
            v-model="email.value"
            class="w-full"
            :invalid="invalid"
            :disabled="email.disabled"
            @blur="email.setTouched()"
          />
        </template>
      </ControlForm>

      <ControlForm
        class="col-span-full"
        label="Пароль"
        :control="password"
      >
        <template #field="{ invalid }">
          <Password
            id="password"
            v-model="password.value"
            toggleMask
            input-class="w-full"
            :invalid="invalid"
            :feedback="false"
            :disabled="password.disabled"
            @blur="password.setTouched()"
          />
        </template>
      </ControlForm>

      <Button
        type="submit"
        class="mt-4 w-full"
        :label="buttonLabel"
        :loading="loginPending"
        :disabled="form.disabled"
      ></Button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useToast } from 'primevue/usetoast'
import { useModel } from '../../model'
import { ControlForm, emailValidator, requiredValidator } from '@/shared'
import { useForm } from '@/shared/form'
import { storeToRefs } from 'pinia'
import Button from 'primevue/button'
import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import { loginResponseAdapter } from '../../lib'
import { computed } from 'vue'

type LoginForm = {
  email: string
  password: string
}

const toast = useToast()
const model = useModel()

const { login } = model
const { loginPending } = storeToRefs(model)

const form = useForm<LoginForm>({
  email: {
    validators: [requiredValidator, emailValidator]
  },
  password: {
    validators: [requiredValidator]
  }
})

const { email, password } = form.controls

const buttonLabel = computed(() => {
  if (loginPending.value) {
    return 'Авторизация...'
  }

  return 'Войти'
})

async function onSubmit() {
  if (loginPending.value) return

  const formIsValid = await form.validate()

  if (formIsValid) {
    const values = form.getValues() as LoginForm
    const res = await login(values)

    // if (res?._code !== 0) {
    //   toast.add({
    //     severity: 'error',
    //     summary: 'Авторизация',
    //     detail: loginResponseAdapter(res?._code, res?._message)
    //   })
    // }
  }
}
</script>
