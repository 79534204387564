<template>
  <AutoComplete
    v-model="localModel"
    v-bind="{
      disabled,
      placeholder,
      invalid
    }"
    completeOnFocus
    autoOptionFocus
    input-class="w-full"
    option-label="title"
    :class="{ 'pointer-events-none': readonly }"
    :input-id="id"
    :suggestions="options"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
  />
</template>

<script setup lang="ts">
import { defineModel, ref, watch } from 'vue'
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete'
import { Brokerclient, useBrokerclientGetList, userIsAdmin } from '@/shared'
import { storeToRefs } from 'pinia'
import { User } from '@/entities/user'
import { useAuthModel } from '@/features/auth'

const props = defineProps<{
  id?: string
  disabled?: boolean
  placeholder?: string
  invalid?: boolean
  readonly?: boolean
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
}>()

const model = defineModel<number | number[] | null>()
const localModel = ref<Brokerclient | Brokerclient[]>()
const options = ref<Brokerclient[]>([])
const { post } = useBrokerclientGetList()
const { user } = storeToRefs(useAuthModel())

watch(localModel, localModelValue => {
  if (localModelValue) {
    if (Array.isArray(localModelValue)) {
      if (localModelValue.length) {
        model.value = localModelValue.map(brokerclient => brokerclient.id)
      } else {
        model.value = null
      }
    } else if (typeof localModelValue === 'string') {
      model.value = null
    } else {
      model.value = localModelValue.id
    }
  } else {
    model.value = null
  }

})

async function getList(query: string) {
  const res = await post({
    _page_size: 5,
    _filter: {
      title: `%${query?.trim()}%`,
      id: userIsAdmin(user.value as User) ? undefined : user.value?.brokerclient_staff
    }
  })

  return res?._data || []
}

async function search(event: AutoCompleteCompleteEvent) {
  options.value = await getList(event.query)
}
</script>
