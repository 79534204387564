<template>
  <div class="flex gap-2">
    <slot></slot>

    <label
      class="text-sm text-gray-600 mb-1"
      v-bind="props"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id?: string
  label?: string
}>()
</script>
