import { RouteRecordRaw } from 'vue-router'

export const routesNames = {
  base: 'User',
}

export const routes: RouteRecordRaw = {
  path: 'user',
  component: () => import('@/pages/UserPage'),
  name: routesNames.base,
}