<template>
  <BaseContent>
    <BaseTitleModal
      label="Разделение файлов"
      @close="onClose"
    />

    <BaseDiver />

    <ScanList
      v-if="scans"
      :scans="scans"
    />

    <ScanFile
      v-else
      :disabled="disabled"
      :onDivideClick="onDivideClick"
      :disabledReason="disabledReason"
    >
      <InputScan
        v-model="file"
        :with-merge-button="false"
    /></ScanFile>
  </BaseContent>
</template>

<script setup lang="ts">
import { BaseContent, BaseDiver, BaseTitleModal, InputScan } from '@/shared'
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import { Ref, computed, inject, ref } from 'vue'
import { useUtilsPdfDivideModel } from '../../../model/model'
import { ScanList } from '../ScanList'
import { ScanFile } from '../ScanFile'

const { scans, divide, closeModal, disabledReason } = useUtilsPdfDivideModel()

const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')
const file = ref<File | null>(null)
const disabled = computed(() => !disabledReason || !file.value)

const onDivideClick = (): void => {
  if (file.value) {
    divide(file.value)
  }
}

const onClose = (): void => {
  closeModal()
  dialogRef?.value.close()
}
</script>
