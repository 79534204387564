<template>
  <BasePanel
    v-model:collapsed="panelCollapsed"
    class="h-fit"
  >
    <template #header>
      <div class="w-full flex justify-between items-center">
        <div class="flex flex-col w-fit">
          <div class="flex gap-2 items-center">
            <!-- <Dropdown
              v-model="role"
              class="text-xl min-w-[200px]"
              filter
              show-clear
              option-label="title"
              option-value="code"
              :options="qwe"
              @click.stop
            /> -->

            <span class="text-gray-900 text-xl font-medium">{{ customerTypeName }}</span>
          </div>
          <span class="text-gray-500 font-medium">{{ fio }}</span>
        </div>

        <div>
          <slot name="header-actions"></slot>
        </div>
      </div>
    </template>

    <template #content>
      <slot name="content"></slot>
    </template>
  </BasePanel>
</template>

<script setup lang="ts">
import { BasePanel } from '@/shared'
import { computed, unref, watch } from 'vue'
import { Customer, getCustomerFio } from '@/entities/customer'
import { useMortgageCustomerModel } from '../../model'
// import Dropdown from 'primevue/dropdown'

type MortgageCustomerProps = {
  customer_id?: string,
  role?: string
}

const emits = defineEmits<{
  created: [Customer]
}>()


const props = defineProps<MortgageCustomerProps>()

const { customerRole, customersRoleDict, customer, customerType, panelCollapsed } = useMortgageCustomerModel(props.customer_id)

if (props.role && !customerRole.value) {
  customerRole.value = unref(props.role)
}


const fio = computed(() => (customer.value ? getCustomerFio(customer.value) : null))
const customerTypeName = computed(() => {
  if (customerType.value === 'main') {
    return 'Заёмщик'
  }

  if (customerRole.value) {
    return customersRoleDict.find(dict => dict.code === customerRole.value)?.title
  }


  return 'Созаёмщик'
})

if (!customer.value) {
  const stopWatch = watch(customer, customer => {
    if (customer) {
      emits('created', customer)
      stopWatch()
    }
  })
}
</script>
