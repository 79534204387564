import { ApiFile } from "@/shared"

export function getApiFileName(file: ApiFile) {
  let index = file.file?.match(/([0-9]{4})\.([0-9]{2})\.([0-9]{2})/)?.index
  if (index) {
    index += 17
    try {
      return decodeURI(file.file.substring(index))
    } catch {
      return file.file
    }
  }

  return 'Название файла не найдено'
}
