import { Ref, UnwrapRef, ref } from 'vue'
import { useBaseUrl, useToken } from '@/shared'

type ApiFetchResponseType = 'blob' | 'json'

export type ApiFetchResponse<T> = {
  _code: number
  _message?: string
  _detail?: { [param: string]: string[] }
  _data_total_size?: number
  _data?: T
}

type ApiFetchOptionsBlob<Data> = {
  _data?: Data
  _sort?: string[]
  _fields?: string[]
  _page_size?: number
  _start_index?: number
  _filter?: {
    [index: string]: any
  }
}

export type ApiFetchOptions = {
  _data?: any
  _sort?: string[] | string
  _fields?: string[]
  _page_size?: number
  _start_index?: number
  _filter?: {
    [index: string]: any
  }
}

export type ApiFetchJson<ApiBody extends ApiFetchOptions, Response> = {
  response: Ref<ApiFetchResponse<Response> | null>
  error: Ref<string | null>
  pending: Ref<boolean>
  post(apiOptions: ApiBody): Promise<ApiFetchResponse<Response> | null>
  abort(): void
}

export type ApiFetchJsonNoBody<Response> = {
  response: Ref<ApiFetchResponse<Response> | null>
  error: Ref<string | null>
  pending: Ref<boolean>
  post(): Promise<ApiFetchResponse<Response> | null>
  abort(): void
}

export type ApiFetchBlob<Body> = {
  response: Ref<Blob | null>
  error: Ref<string | null>
  pending: Ref<boolean>
  post(apiOptions?: ApiFetchOptionsBlob<Body>): Promise<Blob | null>
  abort(): void
}

export function useApiFetch<ApiBody extends ApiFetchOptions, Response>(
  url: string,
  options: RequestInit,
  responseType: 'json'
): ApiFetchJson<ApiBody, Response>
export function useApiFetch<Response>(
  url: string,
  options: RequestInit,
  responseType: 'json'
): ApiFetchJsonNoBody<Response>
export function useApiFetch<Body>(url: string, options: RequestInit, responseType: 'blob'): ApiFetchBlob<Body>
export function useApiFetch<Body, Response>(url: string, options: RequestInit, responseType: ApiFetchResponseType) {
  const baseUrl = useBaseUrl() + 'api/v1/'
  const response = ref<ApiFetchResponse<Response> | Blob | null>(null)
  const error = ref<string | null>(null)
  const pending = ref<boolean>(false)
  const reloadOnNotAuth = url.includes('check_login') ? false : true
  let abortController: AbortController | null = null

  options.headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json; text/html; image/png',
    ...options.headers
  }

  function abort() {
    if (abortController) {
      abortController.abort()
    }
  }

  async function post(apiOptions: ApiFetchOptionsBlob<Body> = {}) {
    abortController = new AbortController()

    error.value = null
    pending.value = true

    const body = JSON.stringify({
      _token: useToken(),
      ...((options.body as object) || {}),
      ...apiOptions
    })

    try {
      const res = await fetch(baseUrl + url + '/', {
        signal: abortController.signal,
        method: 'POST',
        ...options,
        body
      })

      if (res.ok) {
        if (responseType === 'json') {
          response.value = (await res.json()) as UnwrapRef<ApiFetchResponse<Response>>

          if (reloadOnNotAuth && response.value?._code === 1) {
            location.reload()
          }

          if (response.value._message) {
            error.value = response.value._message
          }
        } else if (responseType === 'blob') {
          response.value = await res.blob()
        }
      } else {
        if (res.status === 404) throw new Error('404, Not found')
        if (res.status === 500) throw new Error('500, internal server error')
        throw new Error(res.statusText)
      }
    } catch (err) {
      error.value = err as string
    }

    pending.value = false

    return response.value
  }

  return { response, error, pending, post, abort }
}