import { inject, provide } from 'vue'

/**
 *  Функция c надстройкой над provide/inject
 *   - Если provide с таким же name уже был реализован выше по иерархии, то подтянет его, иначе создаст новый
 */
export function useProvide<T>(name: string, createFn: () => T) {
  return () => {
    const injectData = inject(name, null)

    if (injectData === null) {
      const createData = createFn()
      provide(name, createData)
      return createData
    }

    return injectData as T
  }
}
