import { MortgageCreateApp, MortgageDoctype, MortgageScan, MortgageUpdateApp, useApiFetch } from '@/shared'
import { BankName, DictList, DictValue, TObject, b64File } from '@/shared'
import { MortgageAppFields, MortgageBank } from '@/shared'

import { useMemoize } from '@vueuse/core'
import {
  Mortgage,
  MortgageCalculate,
  MortgageCalculateProgram,
  MortgageLimits,
  MortgageProgram,
  MortgagePrintTemplatesNames
} from '../types'

export type MortgageDictNames =
  | 'ApartmentType'
  | 'MortgageType'
  | 'PledgeType'
  | 'PledgeReadiness'
  | 'PledgeObject'
  | 'Campaign'
  | 'IncomeConfirmationDocument'
  | 'RealEstateObject'
  | 'TypeOfProperty'
  | 'KinshipCoborrower'

export type MortgagePrintContext = {
  customer_id?: string
}

const getDict = useMemoize(async (dictName: MortgageDictNames) => {
  const { post } = useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list`, {}, 'json')
  const res = await post()
  return res?._data
})

const appFields = useMemoize(async (bank: BankName) => {
  const { post } = useApiFetch<{ _data: { bank: BankName } }, MortgageAppFields>(
    baseUrl + 'app/fields/required',
    {},
    'json'
  )
  const res = await post({ _data: { bank } })
  return res?._data
})

type MortgageGetList = {
  _filter?: {
    salespoint_id?: number[]
    id?: number
    id__icontains?: number
    main_customer__last_name__icontains?: string
  }
  _sort: '-created_at'
  _page_size: number
  _start_index: number
}

const baseUrl = 'mortgage/'
export const mortgageApi = {
  /** Получение заявки по id */
  get: () => useApiFetch<{ _data: { id: number } }, Mortgage>(baseUrl + 'get', {}, 'json'),
  /** Получение списка заявок */
  getList: () => useApiFetch<MortgageGetList, Mortgage[]>(baseUrl + 'get_list', {}, 'json'),
  /** Получить справочник */
  getDict,
  /** Клонировать заявку */
  clone: () =>
    useApiFetch<{ _data: { id: number; salespoint_id: number } }, Mortgage>(baseUrl + 'app/clone', {}, 'json'),
  /** Расчет стоимости ипотеки */
  calculate: () =>
    useApiFetch<{ _data: MortgageCalculate }, MortgageCalculateProgram[]>(baseUrl + 'calculate', {}, 'json'),
  /** Лимиты банковских продуктов */
  limits: () => useApiFetch<{ _data: { salespoint_id: number } }, MortgageLimits>(baseUrl + 'limits', {}, 'json'),
  /** Получить банковский продукт */
  getProgram: () =>
    useApiFetch<{ _data: { id: number } }, MortgageProgram>(baseUrl + 'mortgagedictprogramcode/get', {}, 'json'),
  /** Создать/обновить заявку */
  updateApp: () =>
    useApiFetch<{ _data: MortgageUpdateApp | MortgageCreateApp }, Mortgage>(baseUrl + 'app/update', {}, 'json'),
  /** Обязательные/необходимые поля для анкеты конкретного банка */
  appFields,
  /** Получить загруженные сканы заявки */
  getScans: () =>
    useApiFetch<{ _data: { mortgageapp_id: number } }, MortgageScan[]>(baseUrl + 'scans/files/get_list', {}, 'json'),
  /** Загрузить скан в заявку */
  uploadScan: () =>
    useApiFetch<
      { _data: { mortgageapp_id: number; customer_id: string; file: b64File; scan_type: DictValue } },
      MortgageScan
    >(baseUrl + 'scans/files/upload', {}, 'json'),
  /** Удалить сканы в заявке */
  deleteScan: () =>
    useApiFetch<{ _data: { mortgageapp_id: number; customer_id: string; item_id: number } }, unknown>(
      baseUrl + 'scans/files/delete',
      {},
      'json'
    ),
  /** Получить список обязательных сканов для заявки */
  getDoctypes: () =>
    useApiFetch<{ _data: { id: number } }, MortgageDoctype[]>(baseUrl + 'scans/doctypes/get_list', {}, 'json'),
  /** Прикрепление/обновление созаёмщика к заявке */
  updateAppCustomer: () =>
    useApiFetch<
      { _data: { id: number; customer_id: string; role_coborrower?: string; kinship_coborrower?: string } },
      Mortgage
    >(baseUrl + 'app/customer/update', {}, 'json'),
  /** Удаление созаёмщика из заявки */
  deleteAppCustomer: () =>
    useApiFetch<{ _data: { id: number; customer_id: string } }, Mortgage>(baseUrl + 'app/customer/delete', {}, 'json'),
  /** Отправка заявки */
  send: () => useApiFetch<{ _data: { id: number } }, { app: MortgageBank }>(baseUrl + 'app/send', {}, 'json'),
  /** Переотправка сканов */
  scansSend: () => useApiFetch<{ _data: { id: number } }, Mortgage>(baseUrl + 'scans/send', {}, 'json'),
  /** Получить печатную форму */
  print: () =>
    useApiFetch<{
      mortgageapp: number
      template: MortgagePrintTemplatesNames
      context?: {
        customer_id: string
      }
    }>(baseUrl + 'print_form', {}, 'blob'),
  /** Получить печатные формы для анкет */
  getScansApplication: () =>
    useApiFetch<{ _data: { id: number } }, TObject<string, string>>(baseUrl + 'scans/applications/get', {}, 'json'),
  /** Получить банковскую заявку привязанную к ипотеке  */
  getBankApp: () => useApiFetch<{ _data: { id: number } }, { app: MortgageBank }>(baseUrl + 'app/bankapp', {}, 'json'),
  /** Отмена заявки */
  cancel: () => useApiFetch<{ _data: { id: number; reason: string } }, Mortgage>(baseUrl + '/cancel', {}, 'json')
}

// async function getMortgageAppFull(id: number) {
//   const appRes = await mortgageApi.get().post({ _data: { id } })
//   if (appRes?._code == 0 && appRes._data) {
//     const app = appRes._data
//     const salespointPromise = salespointApi.get().post({ _data: { id: app.salespoint_id } })
//     const programPromise = mortgageApi.getProgram().post({ _data: { id: app.program_id } })
//     const userPromise = userApi.get().post({ _data: { id: app.user_id } })
//     const [salespointRes, programRes, userRes] = await Promise.all([salespointPromise, programPromise, userPromise])
//     if (salespointRes?._data && programRes?._data && userRes?._data) {
//       return {
//         ...app,
//         user: userRes._data,
//         program: programRes._data,
//         salespoint: salespointRes?._data
//       }
//     }
//   }

//   return null
// }

/** Получение справочника */
export const useGetDictMortgage = useMemoize(async (dictName: MortgageDictNames) => {
  const { post } = useApiFetch<DictList>(baseUrl + `dict/${dictName}/get_list`, {}, 'json')
  return await post()
})

/** Получение заявки по id */
export const useGetMortgage = () => useApiFetch<{ _data: { id: number } }, Mortgage>(baseUrl + 'get', {}, 'json')

/** Получение списка заявок */
export const useGetMortgageList = () => useApiFetch<MortgageGetList, Mortgage[]>(baseUrl + 'get_list', {}, 'json')

/** Клонировать заявку */
export const useCloneMortgage = () =>
  useApiFetch<{ _data: { id: number; salespoint_id: number } }, Mortgage>(baseUrl + 'app/clone', {}, 'json')

/** Расчет стоимости ипотеки */
export const useCalculateMortgage = () =>
  useApiFetch<{ _data: MortgageCalculate }, MortgageCalculateProgram[]>(baseUrl + 'calculate', {}, 'json')

/** Получение лимитов по банковским продуктам */
export const useGetMortgageLimits = () =>
  useApiFetch<{ _data: { salespoint_id: number } }, MortgageLimits>(baseUrl + 'limits', {}, 'json')

/** Создание/обновление заявки */
export const useUpdateMortgage = () =>
  useApiFetch<{ _data: MortgageUpdateApp | MortgageCreateApp }, Mortgage>(baseUrl + 'app/update', {}, 'json')

/** Получение банковского продукта */
export const useGetMortgageProgram = () =>
  useApiFetch<{ _data: { id: number } }, MortgageProgram>(baseUrl + 'mortgagedictprogramcode/get', {}, 'json')

/** Получение обязательных/необходимых полей для анкеты конкретного банка */
export const useGetRequiredMortgageFields = useMemoize(async (bank: BankName) => {
  const { post } = useApiFetch<{ _data: { bank: BankName } }, MortgageAppFields>(
    baseUrl + 'app/fields/required',
    {},
    'json'
  )

  return await post({ _data: { bank } })
})

/** Отправление ипотечной заявки в банк */
export const useSendMortgage = () =>
  useApiFetch<{ _data: { id: number } }, { app: MortgageBank }>(baseUrl + 'app/send', {}, 'json')

/** Получение списка загруженных сканов ипотечной заявки */
export const useGetMortgageScans = () =>
  useApiFetch<{ _data: { mortgageapp_id: number } }, MortgageScan[]>(baseUrl + 'scans/files/get_list', {}, 'json')

/** Загрузка скана в ипотеке заявке */
export const useUploadMortgageScan = () =>
  useApiFetch<
    { _data: { mortgageapp_id: number; customer_id: string; file: b64File; scan_type: DictValue } },
    MortgageScan
  >(baseUrl + 'scans/files/upload', {}, 'json')

/** Удалить сканы в ипотечной заявке */
export const useDeleteMortgageScan = () =>
  useApiFetch<{ _data: { mortgageapp_id: number; customer_id: string; item_id: number } }, unknown>(
    baseUrl + 'scans/files/delete',
    {},
    'json'
  )

/** Получение списка обязательных сканов для заявки */
export const useGetMortgageDoctypes = () =>
  useApiFetch<{ _data: { id: number } }, MortgageDoctype[]>(baseUrl + 'scans/doctypes/get_list', {}, 'json')

/** Прикрепление/обновление созаёмщика в заявке */
export const useUpdateMortgageCustomer = () =>
  useApiFetch<{ _data: { id: number; customer_id: string } }, Mortgage>(baseUrl + 'app/customer/update', {}, 'json')

/** Удаление созаёмщика из заявки */
export const useDeleteMortgageCustomer = () =>
  useApiFetch<{ _data: { id: number; customer_id: string } }, Mortgage>(baseUrl + 'app/customer/delete', {}, 'json')

/** Отправка/переотправка сканов */
export const useSendMortgageScans = () =>
  useApiFetch<{ _data: { id: number } }, Mortgage>(baseUrl + 'scans/send', {}, 'json')

/** Получение банковскую заявку привязанную к ипотеке  */
export const useGetMortgageBankApp = () =>
  useApiFetch<{ _data: { id: number } }, { app: MortgageBank }>(baseUrl + 'app/bankapp', {}, 'json')

/** Отмена заявки */
export const useCancelMortgage = () =>
  useApiFetch<{ _data: { id: number; reason: string } }, Mortgage>(baseUrl + 'cancel', {}, 'json')

/** Получить печатные формы ипотеки */
export const useMortgagePrint = () =>
  useApiFetch<{
    mortgageapp: number
    template: MortgagePrintTemplatesNames
    context?: MortgagePrintContext
  }>(baseUrl + 'print_form', {}, 'blob')
