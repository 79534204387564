import { reactive } from 'vue'
import { DictList, GeneralDictNames, useGetGeneralDict } from '@/shared'

const dictState = reactive<{ [key in GeneralDictNames]?: DictList }>({})

/**
 * справочники кастомера
 */
export const generalDicts = new Proxy(dictState, {
  get(target, prop: GeneralDictNames) {
    if (typeof prop === 'symbol' || prop.includes('__')) {
      return target[prop]
    }

    if (!target[prop]) {
      useGetGeneralDict(prop)
        .post()
        .then(res => {
          dictState[prop] = res?._data
        })
    }

    return target[prop]
  }
})
