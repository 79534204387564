import { routesNames as accountRouteNames } from './auth'
import { routesNames as chatmessageRouteNames } from './chatmessage'
import { routesNames as reportsRouteNames } from './reports'
import { routesNames as userRouteNames } from './user'

export { routes as authRoutes } from './auth'
export { routes as chatmessageRoutes } from './chatmessage'
export { routes as reportsRoutes } from './reports'
export { routes as userRoutes } from './user'

export const routesNames = {
  user: userRouteNames,
  account: accountRouteNames,
  reports: reportsRouteNames,
  chatmessage: chatmessageRouteNames
}
