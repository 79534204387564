<template>
  <div class="flex items-center">
    <InputRub
      v-model="model"
      class="flex-grow"
      :invalid="invalid"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      :inputId="inputId"
      :pt="{
        input: {
          root: 'border-r-0 rounded-r-none'
        }
      }"
      @blur="emits('blur', $event)"
      @focus="emits('focus', $event)"
    />

    <InputNumber
      v-model="percent"
      :invalid="invalid"
      :readonly="readonly"
      :disabled="disabled"
      :max="100"
      :min="minPercent"
      :max-fraction-digits="2"
      :pt="{
        input: {
          root: 'w-[56px] rounded-s-none text-right'
        }
      }"
      @blur="emits('blur', $event)"
      @focus="emits('focus', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { watchIgnorable } from '@vueuse/core'
import { defineModel, ref } from 'vue'
import { InputRub } from '@/shared'
import InputNumber, { InputNumberBlurEvent } from 'primevue/inputnumber'

const props = withDefaults(
  defineProps<{
    amount?: number | null
    minPercent?: number
    invalid?: boolean
    readonly?: boolean
    disabled?: boolean
    placeholder?: string
    inputId?: string
  }>(),
  {
    minPercent: 0
  }
)

const emits = defineEmits<{
  blur: [InputNumberBlurEvent]
  focus: [Event]
}>()

const model = defineModel<number | null>()
const percent = ref<number | null>(null)

if (model.value) {
  setPercent()
}

const { ignoreUpdates: ignoreUpdatesModelAmount } = watchIgnorable(
  [model, () => props.amount],
  () => {
    ignoreUpdatesPercent(() => {
      setPercent()
    })
  }
  // { immediate: true }
)

const { ignoreUpdates: ignoreUpdatesPercent } = watchIgnorable(percent, percent => {
  ignoreUpdatesModelAmount(() => {
    if (percent && props.amount) {
      model.value = Math.floor((props.amount / 100) * percent)
    } else {
      model.value = null
    }
  })
})

function setPercent() {
  if (model.value && props.amount) {
    percent.value = Math.round((model.value / (props.amount / 100)) * 10) / 10
  } else {
    percent.value = null
  }
}
</script>
