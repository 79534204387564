<template>
  <Button
    v-tooltip="'Разделить pdf-файл'"
    text
    rounded
    icon="pi pi-file-pdf"
    severity="secondary"
    @click="openDivideModal"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { useDialog } from 'primevue/usedialog'
import { PdfDivideModal } from './components/PdfDivideModal'

const dialog = useDialog()

const openDivideModal = () => {
  dialog.open(PdfDivideModal, {
    props: {
      modal: true,
      showHeader: false,
      contentClass: 'p-0 bg-transparent',
      pt: {
        root: 'border-0 sm:w-[548px]'
      }
    }
  })
}
</script>
