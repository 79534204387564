<template>
  <PickList
    v-model:selected="selected"
    value="id"
    label="title"
    selected-list-title="Выбранные группы торговых точек"
    unselected-list-title="Доступные группы торговых точек"
    :items="list"
    :loading="pending"
    :show-more="showMore"
    :invalid="invalid"
    :disabled="disabled"
    @scroll-down="getList"
    @first-list-search="onSearch"
    @show-more="getList"
    @on-change="emits('onChange', $event)"
  />
</template>

<script setup lang="ts">
import { Salespointgroup, useGetSalespointgroupList } from 'simpleloan_api'
import { ref, computed, onBeforeMount, defineModel } from 'vue'
import { PickList } from '../PickList'

defineProps<{
  invalid?: boolean
  disabled?: boolean
}>()

const emits = defineEmits<{
  onChange: [any[] | null]
}>()

const selected = defineModel<number[] | null>('selected', { default: null })
const pageSize = 15
const page = ref<number>(0)
const list = ref<Salespointgroup[]>([])
const totalSize = ref<number | null>(null)
const _getList = useGetSalespointgroupList()
const pending = computed(() => _getList.pending.value)

const showMore = computed(() =>
  Boolean(list.value && totalSize.value && list.value?.length < totalSize.value && !pending.value)
)

onBeforeMount(() => getList())

async function getList() {
  if (_getList.pending.value || (list.value && totalSize.value && list.value?.length >= totalSize.value)) {
    return
  }

  const res = await _getList.post({
    search: {
      _page_size: pageSize,
      _start_index: page.value * pageSize,
      _filter: lastSearchStr
        ? {
            title: `%${lastSearchStr?.trim()}%`
          }
        : undefined
    }
  })

  if (res?._code === 0 && res._data && res._data_total_size) {
    list.value?.push(...res._data)
    totalSize.value = res._data_total_size
    page.value += 1
  }
}

let lastSearchStr: string | null = null
function onSearch(str: string | null) {
  lastSearchStr = str
  list.value = []
  page.value = 0
  totalSize.value = null
  getList()
}
</script>
