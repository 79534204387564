export type FileInBase64 = {
  name: string
  data: string
}

export async function fileTob64(file: File) {
  return new Promise<FileInBase64>(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const b64: string = reader.result as string
      const base64Format = {
        name: file.name,
        data: b64.replace(/^data:.+;base64,/, '')
      }

      resolve(base64Format)
    }
  })
}
