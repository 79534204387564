export function requiredValidator(value: any) {
  if (value || typeof value === 'boolean' || value === 0) {
    return true
  }

  return 'поле обязательно'
}

export function requiredValidatorWithoutBoolAndZero(value: any) {
  if (value) {
    return true
  }

  return 'поле обязательно'
}