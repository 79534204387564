import { ComputedRef, computed, nextTick, reactive } from 'vue'
import {
  ControlCreateOptions,
  UnwrapControl,
  ControlSetValueOptions,
  ControlSetErrorOptions,
  ControlValidationOptions,
  ControlError,
  Control,
  useControl
} from '../control'
import { TObject } from '@/shared'

type FormErrors<T> = {
  [K in keyof T]?: string
}

type FormValues<T> = {
  [K in keyof T]?: T[K] | null
}

export type FormInitialValue<T> = {
  [K in keyof T]: ControlCreateOptions<T[K]>
}

type FormControls<T> = {
  [K in keyof T]: UnwrapControl<T[K]>
}

export type Form<T extends TObject<string, unknown>> = {
  controls: FormControls<T>
  errors: ComputedRef<FormErrors<T>>
  values: ComputedRef<FormValues<T>>
  valid: ComputedRef<boolean>
  invalid: ComputedRef<boolean>
  reset(): void
  enable(): void
  disable(): void
  patchValue(values: TObject<string, any>, options?: ControlSetValueOptions): void
  setErrors(errors: TObject<string, string | string[]>, options?: ControlSetErrorOptions): void
  removeControl(key: keyof T): void
  addControl(name: keyof T, controlCreateOptions: ControlCreateOptions): FormControls<T>[keyof T]
  validation(options?: ControlValidationOptions): Promise<boolean>
}

export function useForm<T extends TObject<string, unknown>>(initialValue?: FormInitialValue<T>): Form<T> {
  const controls = reactive<FormControls<T>>({} as FormControls<T>) as FormControls<T>

  const errors = computed(() => {
    const _errors: TObject<string, ControlError> = {}
    Object.keys(controls).forEach(key => {
      _errors[key] = controls[key].meta.error
    })

    return _errors as FormErrors<T>
  })

  const values = computed(() => {
    const _values: TObject<string, any> = {}
    Object.keys(controls).forEach(key => {
      _values[key] = controls[key].model
    })

    return _values as FormValues<T>
  })

  const valid = computed(() => {
    if (Object.values(controls).find((control: Control<any>) => control._meta.invalid)) {
      return false
    }

    return true
  })

  const invalid = computed(() => !valid.value)

  if (initialValue) {
    Object.keys(initialValue).forEach(name => {
      const options = initialValue[name]
      const control = useControl(name, options)
      const obj: TObject<string, Control<unknown>> = {}
      obj[name] = control
      Object.assign(controls, obj)
    })
  }

  function reset() {
    Object.keys(controls).forEach(key => {
      controls[key].reset()
    })
  }

  function patchValue(values: TObject<string, any>, options: ControlSetValueOptions = { setTouched: true }) {
    Object.keys(values).forEach(key => {
      const control = controls?.[key]
      if (control) {
        control.setValue(values[key], options)
      }
    })
  }

  function addControl(name: keyof T, controlCreateOptions: ControlCreateOptions) {
    if (controls[name]) {
      throw new Error(`form have ${name.toString()} control`)
    }

    controls[name] = useControl(name.toString(), controlCreateOptions) as FormControls<T>[keyof T]

    return controls[name]
  }

  function removeControl(key: keyof T) {
    if (controls[key]) {
      delete controls[key]
    }
  }

  function setErrors(
    errors: TObject<string, string | string[]>,
    options: ControlSetErrorOptions = { setTouched: true }
  ) {
    Object.keys(errors).forEach(key => {
      const error = errors?.[key]
      const control = controls?.[key]
      if (control && error) {
        control.setError(error, options)
      }
    })
  }

  async function validation(options: ControlValidationOptions = { setTouched: false }) {
    Object.keys(controls).forEach(controlName => {
      controls[controlName].validation(options)
    })

    await nextTick()

    return valid.value
  }

  function disable() {
    Object.keys(controls).forEach(controlName => {
      controls[controlName].disable()
    })
  }

  function enable() {
    Object.keys(controls).forEach(controlName => {
      controls[controlName].enable()
    })
  }

  return {
    controls,
    errors,
    values,
    valid,
    invalid,
    reset,
    patchValue,
    setErrors,
    removeControl,
    addControl,
    validation,
    disable,
    enable
  }
}
