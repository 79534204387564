<template>
  <AutoComplete
    v-model="model"
    v-bind="props"
    force-selection
    input-class="w-full"
    :option-label="(data: Address) => data.city?.name"
    :suggestions="options"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
    @item-select="optionSelect($event)"
  />
</template>

<script setup lang="ts">
import { defineModel, ref } from 'vue'
import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete'
import { Address, useDadataRepository } from '@/shared';

const props = defineProps<{
  invalid?: boolean
  readonly?: boolean
  disabled?: boolean
  placeholder?: string
  inputId?: string
}>()

const emits = defineEmits<{
  blur: [Event]
  focus: [Event]
  optionSelect: [Address]
}>()

const model = defineModel<string | null | object>()

const dadataRepository = useDadataRepository()
const options = ref<Address[]>()

function getList(query: string) {
  return dadataRepository.getAddress(query, {
    from_bound: { value: 'city' },
    to_bound: { value: 'city' }
  })
}

function optionSelect(event: AutoCompleteItemSelectEvent) {
  emits('optionSelect', event.value)
}

async function search(event: AutoCompleteCompleteEvent) {
  const res = await getList(event.query)
  options.value = res || []
}
</script>
