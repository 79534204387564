<template>
  <AutoComplete
    v-model="model"
    v-bind="{
      disabled,
      placeholder
    }"
    input-class="w-full"
    :class="{ 'pointer-events-none': readonly }"
    :input-id="id"
    :invalid="invalid"
    :suggestions="options"
    option-label="subTitle"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
    @complete="search"
    @item-select="onItemSelect"
  />
</template>

<script setup lang="ts">
import { DadataAddress } from '@/shared'
import { InputProps, InputEmits, useCommondictsIfns, Ifns } from '@/shared'
import AutoComplete, { AutoCompleteCompleteEvent, AutoCompleteItemSelectEvent } from 'primevue/autocomplete'
import { ref, defineModel } from 'vue'

const props = defineProps<InputProps>()
const emits = defineEmits<
  InputEmits & {
    itemSelect: [Ifns]
  }
>()
const model = defineModel<string | null>()

const options = ref<Ifns & { subTitle: string }[]>([] as any)

const { post } = useCommondictsIfns()

async function getList(query: string) {
  function getAddressStr(title: string, address?: DadataAddress) {
    const cityStr = address?.city ? address.city : ''
    const streetStr = address?.street ? address.street : ''
    let str = title
    if (cityStr && streetStr) {
      str += ` (${cityStr}, ${streetStr})`
    } else if (cityStr || streetStr) {
      str += ` (${cityStr || streetStr})`
    }
    return str
  }

  const res = await post({ _data: { inn: query } })
  if (res?._code === 0 && res._data) {
    const ifnsList = res._data
      .filter(ifns => ifns.title)
      .map(ifns => ({ ...ifns, subTitle: getAddressStr(ifns.title || '', ifns.address?.data) }))

    return ifnsList
  }

  return []
}

async function search(event: AutoCompleteCompleteEvent) {
  options.value = await getList(event.query)
}

function onItemSelect(event: AutoCompleteItemSelectEvent) {
  const ifns = event.value as Ifns
  model.value = ifns.inn
  emits('itemSelect', ifns)
}
</script>
