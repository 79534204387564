<template>
  <div>
    <div class="flex flex-col lg:flex-row justify-between gap-4">
      <List
        class="w-full lg:w-1/2"
        :value="value"
        :label="label"
        :items="notSelected"
        :title="unselectedListTitle"
        :loading="loading"
        :show-more="showMore"
        :disabled="disabled"
        @select="select"
        @scroll-down="emits('scrollDown')"
        @search="emits('firstListSearch', $event)"
        @show-more="emits('showMore')"
      />

      <List
        class="w-full lg:w-1/2"
        :value="value"
        :label="label"
        :items="selected"
        :invalid="invalid"
        :title="selectedListTitle"
        :disabled="disabled"
        @select="unselect"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineModel, nextTick, ref, watch } from 'vue'
import { List } from './components'

type GetFn = (item: any) => any

const props = withDefaults(
  defineProps<{
    unselectedListTitle?: string
    selectedListTitle?: string
    items: any[]
    value?: string | GetFn
    label?: string | GetFn
    loading?: boolean
    showMore?: boolean
    invalid?: boolean
    disabled?: boolean
  }>(),
  {
    label: 'label',
    value: 'code'
  }
)

const emits = defineEmits<{
  scrollDown: []
  firstListSearch: [string | null]
  showMore: []
  onChange: [any[] | null]
}>()

/**
 * Модель с value выбранных значений
 */
const selectedModel = defineModel<any[] | null>('selected', { default: null })

/**
 * Выбранные значения
 */
const selected = ref<any[] | null>(null)

/**
 * Не выбранные значения
 */
const notSelected = computed(() => {
  return props.items.filter(item => {
    return !selected.value?.find(selected => getItemValue(selected) === getItemValue(item))
  })
})

/**
 * Следим за изменением выбранных значений
 */
watch(
  selected,
  selected => {
    // Меняем список модели получая только значения value из item
    selectedModel.value = selected ? selected.map(item => getItemValue(item)) : null
    emits('onChange', selectedModel.value)
  },
  { deep: true }
)

/**
 * Выбрать item
 */
async function select(item: any) {
  if (!selected.value) {
    selected.value = []
    await nextTick()
  }

  if (
    selected.value.length &&
    selected.value.find(selected => getItemValue(selected) === getItemValue(item))
  ) {
    return
  }

  selected.value.push(item)
}

/**
 * Получить value из item
 */
function getItemValue(item: any) {
  if (typeof props.value === 'function') {
    return props.value(item)
  } else {
    return item[props.value]
  }
}

/**
 * Убрать item из выбранных
 */
function unselect(item: any) {
  if (selected.value) {
    const index = selected.value.findIndex(selected => getItemValue(selected) === getItemValue(item))
    if (typeof index === 'number' && index >= 0) {
      selected.value.splice(index, 1)

      if (!selected.value.length) {
        selected.value = null
      }
    }
  }
}
</script>
