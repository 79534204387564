<template>
  <BaseContent>
    <div class="text-xl text-gray-900 font-medium mb-4">Банковские продукты</div>

    <DataTable
      v-model:expandedRows="expandedRows"
      scrollable
      size="small"
      class="mortgage-table"
      selectionMode="single"
      :pt="{
        columnheader: 'bg-transparent',
        rowExpansion: 'bg-gray-100',
        rowExpansionCell: 'p-0'
      }"
      :data-key="getDataKey"
      :value="programs"
      :selection="program"
      :loading="loading"
      :metaKeySelection="false"
      @row-select="onRowSelect"
      @row-click="onRowClick"
    >
      <Column
        field="title"
        header="Название"
      >
        <template #body="{ data }">
          <div>{{ data.product.title }}</div>
        </template>
      </Column>

      <Column
        field="bank"
        header="Банк"
      >
        <template #body="{ data }">
          <BaseBankIcon :bankName="data.product.bank" />
        </template>
      </Column>

      <Column
        field="rate"
        header="Ставка"
      >
        <template #body="{ data }"> от {{ data.loan_rate }}% </template>
      </Column>

      <Column
        field="payment"
        header="Ежемес. платеж"
      >
        <template #body="{ data }"> {{ data.monthly_payment.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="sum"
        header="Сумма кредита"
      >
        <template #body="{ data }"> {{ data.credit_amount.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="overpayment"
        header="Переплата"
      >
        <template #body="{ data }"> {{ data.overpayment.toLocaleString() }}₽ </template>
      </Column>

      <Column
        field="down_payment"
        header="ПВ"
      >
        <template #body="{ data }"> {{ data.down_payment.toLocaleString() }}₽ </template>
      </Column>

      <!-- <template #expansion="{ data }">
        <div
          class="grid grid-cols-12 gap-4 p-2"
          :class="{
            hidden: !expansionRowVisible(data)
          }"
        >
          <BaseInputForm
            id="akbars_dream_rate_partner_inn"
            label="ИНН партнера по программе Ставка мечты"
            class="col-span-6"
            required
          >
            <Dropdown
              v-model="akbars_dream_rate_partner_inn"
              filter
              show-clear
              input-id="akbars_dream_rate_partner_inn"
              option-label="title"
              option-value="code"
              :options="(dicts as any).AkbarsDreamRatePartner"
            />
          </BaseInputForm>

          <BaseInputForm
            id="akbars_dream_rate_variant"
            label="Ставка мечты"
            class="col-span-6"
            required
          >
            <InputNumber
              v-model="akbars_dream_rate_variant"
              inputId="akbars_dream_rate_variant"
              suffix="%"
              fluid
            />
          </BaseInputForm>
        </div>
      </template> -->

      <template #empty>
        <div class="text-center">
          <span class="text-gray-900 text-base font-medium">Нет подходящих продуктов</span>
        </div>
      </template>
    </DataTable>
  </BaseContent>
</template>

<script setup lang="ts">
import { computed, defineModel, watch } from 'vue'
import { BaseBankIcon, BaseContent } from '@/shared'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import { MortgageCalculateProgram } from '../../types'
import { useMortgageSecondModel } from '../../model'
import { storeToRefs } from 'pinia'

const props = defineProps<{
  programs: MortgageCalculateProgram[] | null
  loading?: boolean
}>()

const mortgageSecondModel = useMortgageSecondModel()
const { dicts } = storeToRefs(mortgageSecondModel)

const akbars_dream_rate_partner_inn = defineModel<string | null>('akbars_dream_rate_partner_inn', { default: null })
const akbars_dream_rate_variant = defineModel<number | null>('akbars_dream_rate_variant', { default: null })

const programId = defineModel<number | null>('programId')
const program = computed(() => {
  return props.programs?.find(program => program.product.id === programId.value) || null
})

// const expandedRows = ref()
const expandedRows = computed(() => {
  if (programId.value) {
    return (
      props.programs
        ?.filter(program => program.product.id === programId.value)
        ?.map(product => product.product.id)
        .reduce((result, item) => {
          result[item] = true
          return result
        }, {} as Record<number, boolean>) || null
    )
  }

  return null
})

watch(programId, () => {
  akbars_dream_rate_partner_inn.value = null
  akbars_dream_rate_variant.value = null
})

function onRowSelect(event: any) {
  const data = event.data as MortgageCalculateProgram
  programId.value = data.product.id
}

function onRowClick(event: any) {
  const data = event.data as MortgageCalculateProgram
  if (data.product.id === programId.value) {
    programId.value = null
  }
}

function getDataKey(program: MortgageCalculateProgram) {
  return program.product.id.toString()
}

function expansionRowVisible(program: MortgageCalculateProgram) {
  if (program.product.code.includes('DreamRate')) {
    return true
  }

  return false
}
</script>

<style lang="scss">
.mortgage-table {
  th {
    background: white;
  }

  td {
    border: none;
  }
}
</style>
