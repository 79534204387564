<template>
  <BaseContent>
    <BaseTitleModal
      label="Объединение файлов"
      @close="close"
    />

    <BaseDiver />

    <BaseFiles
      v-model="files"
      :disabled="pending"
    />

    <BaseDiver />

    <div
      v-tooltip.bottom="disabledReason"
      class="w-fit mt-2"
    >
      <Button
        label="Объединить"
        :disabled="disabled"
        :loading="pending"
        @click="onSubmit"
      />
    </div>
  </BaseContent>
</template>

<script setup lang="ts">
import {
  BaseContent,
  BaseDiver,
  BaseTitleModal,
  BaseFiles,
  fileTob64,
  FileInBase64,
  useUtilsPdfMergeModel
} from '@/shared'
import { DynamicDialogInstance } from 'primevue/dynamicdialogoptions'
import { Ref, computed, inject, ref } from 'vue'
import Button from 'primevue/button'

const dialogRef = inject<Ref<DynamicDialogInstance>>('dialogRef')
const files = ref<File[]>([])
const { merge, pending } = useUtilsPdfMergeModel()

const disabledReason = computed(() => {
  if (files.value.length < 2) {
    return 'Минимальное необходимое число файлов для объединения 2'
  }

  if (pending.value) {
    return 'Идет объединение файлов...'
  }

  return null
})
const disabled = computed(() => Boolean(disabledReason.value))

async function onSubmit() {
  const promises: Promise<FileInBase64>[] = []
  for (const file of files.value) {
    const promise = fileTob64(file)
    promises.push(promise)
  }

  const filesInBase64 = await Promise.all(promises)
  const mergedFile = await merge(filesInBase64)

  if (mergedFile) {
    dialogRef?.value.close(mergedFile)
  }
}

function close() {
  dialogRef?.value.close()
}
</script>
