import { computedAsync } from '@vueuse/core'
import { ComputedRef, computed, reactive, ref, watch } from 'vue'
import { mortgageApi } from '../api'
import { BankName, Salespoint, User, useGetSalespoint, useGetUser } from '@/shared'
import { ApiFetchResponse, MortgageBank } from '@/shared'
import { TMortgagesCalculatorForm } from './mortgage-store'
import { useSalespointStore, useWebsocketStore } from '@/store'
import { useProvide } from '@/shared'
import { storeToRefs } from 'pinia'
import { useAuthModel } from '@/features/auth'
import { Mortgage, MortgageProgram, MortgageUpdateApp } from '../types'

export type PreMortgageData = {
  program_id: number
  conditions: TMortgagesCalculatorForm
}

export type MortgageModel = {
  mortgage: Mortgage | null
  readonly user: User | null
  readonly program: MortgageProgram | null
  readonly bankApp: MortgageBank | null
  readonly bankName: BankName | null
  readonly salespoint: Salespoint | null
  readonly preMortgageData: PreMortgageData | null
  // loadings
  readonly sendPending: boolean
  readonly updatePending: boolean
  readonly deleteCustomerPending: boolean
  readonly updateCustomerPending: boolean
  // methods
  send(): Promise<ApiFetchResponse<{ app: MortgageBank }> | null>
  create(main_customer_id: string): Promise<ApiFetchResponse<Mortgage> | null | undefined>
  update(data: MortgageUpdateApp): Promise<ApiFetchResponse<Mortgage> | null | undefined>
  updateCustomer(customer_id: string, role_coborrower?: string, kinship_coborrower?: string): Promise<ApiFetchResponse<Mortgage> | null | undefined>
  deleteCustomer(customer_id: string): Promise<ApiFetchResponse<Mortgage> | null | undefined>
  setPreMortgageData(value: PreMortgageData | null): void
  drop(): void
}

/**
 ** Фабричная функция для создания модели ипотечной заявки
 *  - Все методы связанные с конкретной заявкой должны вызываться через модель
 */
export function createMortgageModel(initialValue?: Mortgage): MortgageModel {
  const authModel = useAuthModel()
  const salespointStore = useSalespointStore()
  const { message } = storeToRefs(useWebsocketStore())

  /** Заявка */
  const mortgage = ref<Mortgage | null>(initialValue || null)
  /** Данные, которые хранятся перед созданием заявки */
  const preMortgageData = ref<PreMortgageData | null>(null)

  // const { post: getPost, pending: getPending, abort: getAbort } = mortgageApi.get()
  const { post: getProgramPost, pending: getProgramPending, abort: getProgramAbort } = mortgageApi.getProgram()
  const { post: getBankAppPost, pending: getBankAppPending } = mortgageApi.getBankApp()
  const { post: updatePost, pending: updatePending } = mortgageApi.updateApp()
  const { post: updateAppCustomerPost, pending: updateCustomerPending } = mortgageApi.updateAppCustomer()
  const { post: deleteCustomerPost, pending: deleteCustomerPending } = mortgageApi.deleteAppCustomer()
  const { post: sendPost, pending: sendPending } = mortgageApi.send()

  /** Программа заявки */
  const program = computedAsync(async () => {
    const program_id = mortgage.value?.program_id || preMortgageData.value?.program_id
    if (program_id) {
      const res = await getProgramPost({ _data: { id: program_id } })
      return res?._data || null
    }

    return null
  }, null) as ComputedRef<MortgageProgram | null>

  /** Торговая точка заявки */
  const salespoint = computedAsync(async () => {
    const salespoint_id = mortgage.value?.salespoint_id || salespointStore.salespoint?.id
    if (salespoint_id) {
      const res = await useGetSalespoint().post({ _data: { id: salespoint_id } })
      return res?._data || null
    }

    return null
  }, null) as ComputedRef<Salespoint | null>

  /** Юзер привязанный к заявке */
  const user = computedAsync(async () => {
    const user_id = mortgage.value?.user_id || authModel.user?.id
    if (user_id) {
      const res = await useGetUser().post({ _data: { id: user_id } })
      return res?._data || null
    }

    return null
  }, null) as ComputedRef<User | null>

  /** Заявка банка привязанная к заявке */
  const bankApp = computedAsync(async () => {
    if (mortgage.value?.bank_app_status?.id) {
      const res = await getBankAppPost({ _data: { id: mortgage.value.id } })
      return res?._data?.app || null
    }

    return null
  }, null) as ComputedRef<MortgageBank | null>

  /** Название выбранного банка */
  const bankName = computed(() => mortgage.value?.bank_app_status?.bank || program.value?.bank || null)

  watch(message, message => {
    const { _type, _data } = message
    if (
      mortgage.value &&
      (_type === 'mortgage_update' || _type === 'mortgage_bank_status') &&
      _data.app.id === mortgage.value.id
    ) {
      mortgage.value = _data.app
    }
  })

  async function create(main_customer_id: string) {
    if (preMortgageData.value && salespoint.value) {
      const { conditions, program_id } = preMortgageData.value as any
      const res = await updatePost({
        _data: {
          main_customer_id,
          program_id: program_id,
          salespoint_id: salespoint.value?.id,
          // Значения калькулятора
          ...conditions,
          // Хардкод значений из калькулятора
          consent_type: 'TYPE_1',
          refinancing: preMortgageData.value.conditions.refinancing ? true : false,
          campaign: conditions?.combo_flag ? 'COMBOMORTGAGE_GP' : undefined
        }
      })

      if (res?._data) {
        mortgage.value = res._data
      }

      return res
    }
  }

  //MortgageUpdateApp
  async function update(_data: any) {
    if (mortgage.value) {
      const res = await updatePost({ _data })
      if (res?._code === 0 && res._data) {
        mortgage.value = res._data
      }
      return res
    }
  }

  async function updateCustomer(customer_id: string, role_coborrower?: string, kinship_coborrower?: string) {
    if (mortgage.value) {
      const res = await updateAppCustomerPost({
        _data: {
          id: mortgage.value.id,
          customer_id,
          role_coborrower,
          kinship_coborrower
        }
      })

      if (res?._data) {
        mortgage.value = res._data
      }

      return res
    }
  }

  async function deleteCustomer(customer_id: string) {
    if (mortgage.value) {
      const res = await deleteCustomerPost({ _data: { id: mortgage.value?.id, customer_id } })
      if (res?._code === 0 && res._data) {
        mortgage.value = res._data
      }
      return res
    }
  }

  async function send() {
    if (mortgage.value) {
      const res = await sendPost({ _data: { id: mortgage.value.id } })
      if (res?._code === 0 && res._data) {
        // mortgage.value = res._data
      }
      return res
    }
    return null
  }

  function setPreMortgageData(value: PreMortgageData | null) {
    preMortgageData.value = value
  }

  function drop() {
    mortgage.value = null
  }

  return reactive({
    mortgage,
    program,
    salespoint,
    user,
    bankApp,
    bankName,

    preMortgageData,
    setPreMortgageData,

    create,
    update,
    updatePending,

    updateCustomer,
    updateCustomerPending,

    deleteCustomer,
    deleteCustomerPending,

    send,
    sendPending,

    drop
  })
}

/**
 ** Фабричная функция для создания модели ипотечной заявки
 *  - Использует внутри себя useProvide, который сразу использует inject/provide в зависимости от ситуации.
 *    Если выше по иерархии уже был использован useMortgageModel, то подтянет его через inject, иначе создаст новый и
 *    использует provide
 *  - Если inject/provide мешает или не нужен, то используйте createMortgageModel
 *  - Можно передать уже созданные модель или заявку
 */
export const useMortgageModel = (props?: { mortgage?: Mortgage; model?: MortgageModel }) => {
  return useProvide('mortgage-model', () => {
    if (props?.model) {
      return props.model
    } else {
      return createMortgageModel(props?.mortgage)
    }
  })()
}
