import { useApiFetch } from '@/shared'
import { FileInBase64 } from '@/shared'

export type UtilsPdfMergeRequest = {
  _data: {
    files: FileInBase64[]
  }
}

export type UtilsPdfDivideRequest = {
  _data: {
    file: FileInBase64
    pages: number[][]
  }
}

export type UtilsPdfPreviewRequest = {
  _data: {
    file: FileInBase64
  }
}

const baseUrl = 'utils/'

/** Объединить несколько PDF-файлов в один */
export const useUtilsPdfMerge = () =>
  useApiFetch<UtilsPdfMergeRequest, string>(baseUrl + 'pdf/merge', {}, 'json')

/** Разделить несколько PDF-файлов */
export const useUtilsPdfDivide = () =>
  useApiFetch<UtilsPdfDivideRequest, string[]>(baseUrl + 'pdf/divide', {}, 'json')

/** Предпоказ PDF-файлов */
export const useUtilsPdfPreview = () =>
  useApiFetch<UtilsPdfPreviewRequest, string[]>(baseUrl + 'pdf/preview', {}, 'json')
