import { computed, nextTick, reactive, Ref, ref, toRaw } from 'vue'
import { Control, ControlParams, useControl } from '../../control'

export type Form<T extends Record<string, unknown>> = {
  readonly controls: Controls<T>
  readonly errors: Record<keyof T, string[]>
  readonly disabled: boolean
  readonly isValid: boolean
  readonly isInvalid: boolean

  reset(): void
  enable(): void
  disable(): void
  validate(): Promise<boolean>
  getValues(): ControlsValues<T>
  setErrors(errors: Record<string, string | string[]>): void
  patchValue(values: Record<string, unknown>): void
  addControl(key: keyof T, control: Control<T[keyof T]>): void
  removeControl(key: keyof T): void
}

type Controls<T> = {
  [K in keyof T]: Control<T[K]>
}

type ControlsParams<T> = {
  [K in keyof T]: ControlParams<T[K]>
}

type ControlsValues<T> = {
  [K in keyof T]?: T[K] | null
}

export const useForm = <T extends Record<string, unknown>>(params: ControlsParams<T>): Form<T> => {
  const controls = ref<Controls<T>>({} as Controls<T>) as Ref<Controls<T>>

  const disabled = ref<boolean>(false)

  const errors = computed(() => {
    const obj: Record<string, string[]> = {}

    for (const key in controls.value) {
      obj[key] = controls.value[key].errors
    }

    return obj as Record<keyof T, string[]>
  })

  const isValid = computed(() => {
    for (const key in controls.value) {
      if (controls.value[key].isInvalid) {
        return false
      }
    }

    return true
  })

  const isInvalid = computed(() => !isValid.value)

  setControls()

  function patchValue(values: Record<string, unknown>) {
    Object.keys(values).forEach(key => {
      const newValue = values[key] as any
      const control = controls.value?.[key]
      if (control) {
        control.setValue(newValue)
      }
    })
  }

  function setControls() {
    controls.value = {} as Controls<T>

    for (const key of Object.keys(params) as Array<keyof T>) {
      const param = params[key]
      const control = useControl(param)
      controls.value[key] = control
    }
  }

  function reset() {
    setControls()
  }

  function setErrors(newErrors: Record<string, string | string[]>) {
    Object.keys(newErrors).forEach(key => {
      const errors = newErrors[key]
      const control = controls.value?.[key]
      if (control) {
        control.setErrors(errors)
      }
    })
  }

  function disable() {
    Object.keys(controls.value).forEach(key => {
      const control = controls.value[key]
      control.disable()
    })

    disabled.value = true
  }

  function enable() {
    Object.keys(controls.value).forEach(key => {
      const control = controls.value[key]
      control.enable()
    })

    disabled.value = false
  }

  function getValues() {
    return Object.keys(controls.value).reduce((acc, key) => {
      const control = controls.value[key]
      acc[key as keyof T] = toRaw(control.value)
      return acc
    }, {} as ControlsValues<T>)
  }

  async function validate() {
    Object.keys(controls.value).forEach(key => {
      const control = controls.value[key]
      control.validate()
      control.setTouched()
    })

    await nextTick()

    return isValid.value
  }

  function addControl(key: keyof T, control: Control<T[keyof T]>) {
    if (!controls.value?.[key]) {
      controls.value[key] = control
    }
  }

  function removeControl(key: keyof T) {
    if (controls.value?.[key]) {
      delete controls.value[key]
    }
  }

  return reactive({
    controls,
    errors,
    disabled,
    isValid,
    isInvalid,

    reset,
    enable,
    disable,
    validate,
    setErrors,
    getValues,
    patchValue,
    addControl,
    removeControl
  })
}
