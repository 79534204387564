import { useApiFetch } from '@/shared'
import { Customer, CustomerCreate, CustomerUpdate } from '@/entities/customer'

export type CustomerFindData = {
  last_name: string
  passport_number: string
}

const baseUrl = 'customer/'
export const customerApi = {
  /** Поиск кастомера */
  search: () => useApiFetch<{ _filter: CustomerFindData; _pageSize: 1 }, Customer[]>(baseUrl + 'get_list', {}, 'json'),
  /** Обновление кастомера */
  update: () => useApiFetch<{ _data: CustomerCreate | CustomerUpdate }, Customer>(baseUrl + 'update', {}, 'json')
}

// /** Поиск кастомера с определенной фамилией и номером паспорта */
// function search(last_name: string, passport_number: string) {
//   return repository.request<Customer[]>({
//     endPoint: 'get_list/',
//     params: {
//       _filter: {
//         last_name,
//         passport_number
//       },
//       _page_size: 1
//     }
//   })
// }
