export function minValidator(min: number, errorStr?: string) {
  return (value: number | string) => {
    if (value && typeof value === 'number' && value < min) {
      return errorStr || `Минимальное значение ${min}`
    }

    if (value && typeof value === 'string' && value.length < min) {
      return errorStr || `Минимальное значение ${min}`
    }

    return true
  }
}
