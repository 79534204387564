import { RouteRecordRaw } from 'vue-router'

export const routesNames = {
  base: 'Reports',
  loan: 'Reports Loan',
  stat: 'Reports Stat'
}

export const routes: RouteRecordRaw = {
  path: 'reports',
  name: routesNames.base,
  redirect: { name: routesNames.loan },
  children: [
    {
      path: 'loan',
      component: () => import('@/pages/ReportsMortgageLoanPage'),
      name: routesNames.loan
    },
    {
      path: 'stat',
      component: () => import('@/pages/ReportsMortgageStatPage'),
      name: routesNames.stat
    }
  ]
}
