import { useProvide } from "@/shared"

export type ModelType = 'local' | 'provide'

/**
 * Функция обертка для создания моделей. 
 * @param name - имя, которое будет использоваться для провайда модели
 * @param createModelFn - фабричная функция создания модели
 * @param type  - тип создания 
 * @param model - модель
 * @returns модель
 */
export function useModel<T>(name: string, createModelFn: () => T, type: ModelType, model?: T) {
  if (type === 'provide') {
    if (model) {
      return useProvide(name, () => model)()
    } else {
      return useProvide(name, createModelFn)()
    }
  } else {
    if (model) {
      return model
    } else {
      return createModelFn()
    }
  }
}