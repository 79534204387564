type Props = {
  href: string
  name: string
}

export const downloadImage = ({ href, name }: Props): void => {
  const link = document.createElement('a')
  link.href = href
  link.download = name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
