<template>
  <div class="flex flex-col">
    <InputMask
      ref="inputMask"
      v-model="localDate"
      v-bind="{
        id,
        disabled,
        placeholder,
        invalid,
        readonly
      }"
      mask="99.99.9999 - 99.99.9999"
      slotChar="дд.мм.гггг - дд.мм.гггг"
      :autoClear="false"
      @blur="onBlur"
      @focus="onFocus"
      @keydown.tab="onTab"
    />

    <Calendar
      ref="calendar"
      v-model="calendarModel"
      selectionMode="range"
      inputClass="hidden"
    />
  </div>
</template>

<script setup lang="ts">
import { defineModel, nextTick, ref, watch } from 'vue'
import InputMask from 'primevue/inputmask'
import { InputEmits, InputProps, dateBackendFormat, dateFrontFormat } from '@/shared'
import Calendar from 'primevue/calendar'

const props = defineProps<InputProps>()
const emits = defineEmits<InputEmits>()

const inputMask = ref()
const calendar = ref()
const date1 = defineModel<string | null>('date1')
const date2 = defineModel<string | null>('date2')
const localDate = ref<string | null>()
const calendarModel = ref<Date[]>()

setLocalDateValue()

// watch(
//   model,
//   date => {
//     if (date) {
//       const [year, month, day] = date.split('-')
//       localDate.value = `${day}.${month}.${year}`
//     } else {
//       localDate.value = ''
//     }
//   },
//   { immediate: true }
// )

const regExp = /[а-яА-ЯЁё]/
// При изменении внутреннего значения изменяем внешнее
watch(localDate, date => {

  if (date) {
    const [_date1, _date2] = date.split('-').map(date => date.trim())
    if (!regExp.test(_date1)) {
      date1.value = dateBackendFormat(_date1)
    } else {
      date1.value = null
    }

    if (!regExp.test(_date2)) {
      date2.value = dateBackendFormat(_date2)
    } else {
      date2.value = null
    }
  }
})

watch(calendarModel, calendarModel => {
  if (calendarModel) {
    const [_date1, _date2] = calendarModel
    date1.value = _date1?.toLocaleDateString()
    date2.value = _date2?.toLocaleDateString()
    nextTick(() => {
      setLocalDateValue()
    })
  }
})

function setLocalDateValue() {
  const _date1 = dateFrontFormat(date1.value || '')
  const _date2 = dateFrontFormat(date2.value || '')
  localDate.value = `${_date1} - ${_date2}`
}

function onFocus(event: Event) {
  calendar.value.onFocus(event)
  emits('focus', event)
}

function onBlur(event: Event) {
  emits('blur', event)
}

function onTab(event: Event) {
  calendar.value.overlayVisible = false
}
</script>
