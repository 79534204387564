<template>
  <InputMask
    v-model="model"
    v-bind="{
      id,
      disabled,
      placeholder,
      invalid,
      readonly
    }"
    unmask
    mask="999-999-999 99"
    @blur="emits('blur', $event)"
    @focus="emits('focus', $event)"
  />
</template>

<script setup lang="ts">
import { defineModel } from 'vue'
import InputMask from 'primevue/inputmask'
import { InputEmits, InputProps } from '@/shared';

const props = defineProps<InputProps>()
const emits = defineEmits<InputEmits>()
const model = defineModel<string | null>()
</script>
